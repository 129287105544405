
























































import { Component, Vue, Prop } from "vue-property-decorator"
import Conversation from "~/models/Conversation"

@Component({})
export default class ConversationLastMessageCard extends Vue {
  @Prop({ required: true }) conversation!: Conversation
  @Prop({ required: true }) message!: string
  @Prop({ required: true }) date!: Date
}
