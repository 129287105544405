import { render, staticRenderFns } from "./ConversationLastMessageCard.vue?vue&type=template&id=08eb9fb6&scoped=true&"
import script from "./ConversationLastMessageCard.vue?vue&type=script&lang=ts&"
export * from "./ConversationLastMessageCard.vue?vue&type=script&lang=ts&"
import style0 from "./ConversationLastMessageCard.vue?vue&type=style&index=0&id=08eb9fb6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08eb9fb6",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
installComponents(component, {VBadge,VCard,VCardText})
