import Collection from "../jrdm-mc/Collection"
import Conversation from "../models/Conversation"

export default class Conversations extends Collection<Conversation> {
  model() {
    return Conversation
  }
  static route = "deals/{deal_id}/conversations"
  static responseField = "conversations"

  deal_id: number | null = null
}
