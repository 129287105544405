





















































import { Component, Vue, Prop, Watch } from "vue-property-decorator"
import ConversationLastMessageCard from "@/components/messaging/ConversationLastMessageCard.vue"
import Messaging from "@/components/messaging/Messaging.vue"
import Conversations from "~/collections/Conversations"
import { state } from "@/store"
import House from "~/models/House"
import { Route } from "vue-router"
import PromptIgnorePendingMessage from "@/components/modals/PromptIgnorePendingMessage.vue"

@Component({
  components: {
    ConversationLastMessageCard,
    Messaging,
    PromptIgnorePendingMessage,
  },
})
export default class Messages extends Vue {
  @Prop({ default: null }) dealId!: number
  @Prop({ default: null }) houseId!: number
  conversations = new Conversations()
  loading = false
  selectedConversationId: number | null = null
  activeRadioConversationId: number | null = null
  currentHouse = new House()

  mounted() {
    this.loadConversations()
    this.selectConversationFromRouter()
  }

  async beforeRouteLeave(to: Route, from: Route, next: () => void) {
    const conversationId = +to.params.conversationId
    await this.handlePendingMessage(conversationId, next)
  }

  async beforeRouteUpdate(to: Route, from: Route, next: () => void) {
    const conversationId = +to.params.conversationId
    await this.handlePendingMessage(conversationId, next)
  }

  get isFullHouseMode() {
    const elements = this.$route.name?.split("/") || []
    return elements[elements.length - 2] == "house"
  }

  get selectedConversation() {
    if (!this.conversations || this.selectedConversationId == null) return null
    return this.conversations.items.find(
      (conversation) => conversation.id == this.selectedConversationId
    )
  }

  async handlePendingMessage(conversationId: number, callback: () => void) {
    const conversationIdChanged = conversationId !== this.selectedConversationId
    const prompt = this.$refs
      .promptIgnorePendingMessage as PromptIgnorePendingMessage
    if (state.currentMessage !== "" && conversationIdChanged) {
      prompt.open(() => {
        callback()
      })
    } else callback()
  }
  cancelChangeConversation() {
    this.selectConversationFromRouter()
  }

  routerConversationId() {
    if (this.$route.name == "messages" || this.$route.name == "conversations")
      return null
    const { conversationId } = this.$route.params
    return conversationId ? +conversationId : null
  }
  @Watch("$route")
  selectConversationFromRouter() {
    this.selectedConversationId = this.routerConversationId()
    this.activeRadioConversationId = this.routerConversationId()
  }
  async onSelectConversation(conversationId: number) {
    await this.handlePendingMessage(conversationId, () => {
      if (conversationId == this.selectedConversationId) return
      this.selectedConversationId = conversationId
      this.$router.push(
        `/deals/${state.currentDealId()}/conversations/${conversationId}`
      )
    })
  }
  async loadConversations() {
    const deal_id = state.currentDealId()
    if (!deal_id) return
    this.loading = true
    this.conversations.deal_id = deal_id
    await this.conversations.fetch()
    this.loading = false
  }
  getConversation(conversationId: number) {
    return this.conversations.items.find(({ id }) => id == conversationId)
  }
  getLastMessage(conversationId: number) {
    const conversation = this.getConversation(conversationId)
    if (!conversation) return ""
    return conversation.last_message?.body || ""
  }
  getLastMessageDate(conversationId: number) {
    const conversation = this.getConversation(conversationId)
    if (!conversation) return null
    return conversation.last_message?.created_at || null
  }
  openFullHouseCard(house: House) {
    this.currentHouse = house
    this.$router.push(`${this.$route.path}/house/${house.id}/description`)
  }
}
